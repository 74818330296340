.App {
  text-align: center;
  width: 1440px;
  justify-content: center;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  align-items: center; /* 수직 가운데 정렬 */
  position: relative;
  font-size: 10px;
  color: white;
  width: 100%;
}

.App-header h1 {
  margin: 0;
  padding-left: 20px; /* 왼쪽 여백 추가 */
}

.myInput {
  position: absolute;
  right: 50%;
}

.otherInput {
  position: absolute;
  left: 50%;
}

.App-header input[type="text"] {
  width: 300px;
  margin: 6px;
  padding: 10px;
}

.App-header button {
  position: absolute;
  left: 50%;
  margin-left: 340px;
  padding: 10px;
}

.checkBox {
  position: absolute;
  left: 50%;
  margin-left: 430px;
  padding: 10px;
}

.App-link {
  color: #61dafb;
}

.select {
  width: 100%;
  display: flex;
  justify-content: flex-start; /* 왼쪽 정렬 */
  gap: 10px;
}

.custom-horizontal-scrollbar::-webkit-scrollbar {
  height: 20px; /* 가로 스크롤바의 높이를 16px로 설정 */
}

.custom-horizontal-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-horizontal-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 4px solid #f1f1f1; /* 스크롤바 thumb에 여백 추가 */
}

.custom-horizontal-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.custom-horizontal-scrollbar.show{
  display: block;
}

.custom-horizontal-scrollbar.hide{
  display: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
