.dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
    background-color: #ffffff;
}

.dropdown-header {
    padding: 10px;
    height: 23px;
    border: 1px solid #ccc;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown-header.open {
    border-bottom: 2px solid white;
}

.dropdown-header.disabled {
    cursor: not-allowed;
    opacity: 0.5; /* 비활성화 시 시각적으로 흐리게 표시 */
    pointer-events: none; /* 클릭 이벤트 무효화 */
}

.dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    background-color: white;
    z-index: 1000;
}

.dropdown-option {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.dropdown-option:hover {
    background-color: #f0f0f0;
}

.dropdown-icon {
    margin-right: 10px;
    width: 20px;
    height: 20px;
}

.dropdown-arrow {
    margin-left: auto;
}

.dropdown-arrow.open {
    transform: rotate(180deg);
}

.checkbox {
    width: calc(25% - 9px);
    margin: 6px;
    margin-bottom: 0;
    height: 45px;
}
.checkbox.left{
    margin-left: 0;
}

.checkbox.right {
    margin-right: 0;
}
.checkbox-grid {
    display: flex;
    border: 1px solid #ccc;
    flex-wrap: wrap;
    background-color: white;
    column-gap: 11px;
    margin-top: 0;
}

.checkbox-item {
    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis;
    width: calc(25% - 8.5px);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 45px;
}

.checkbox-text {
    display: inline-flex;
    align-items: center; /* 세로 가운데 정렬 */
    height: 33px;
    font-size: 20px;
    width: 100%;
    text-align: left;
    line-height: 1;
    padding: 5px;
    position: relative;
}

.checkbox-text::after {
    content: "";
    position: absolute;
    left: 5px; /* 왼쪽 여백 */
    right: 5px; /* 오른쪽 여백 */
    bottom: 0;
    height: 2px; /* 밑줄의 두께 */
    background-color: black; /* 밑줄 색상 */
}

.chartbox {
    width: calc(25% - 6px);
    margin: 0px 3px 0px 3px;
    height: 45px;
}

.chartbox.left {
    margin-left: 0;
}